import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap'; // Check out drizzle's react components at @drizzle/react-components
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'
import Carousel from "react-simply-carousel";

class Pirates extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      activeSlideIndex: 0,
      showModal: false,
      modalBackground: ""
    }

  }

  setActiveSlideIndex = (newActiveSlideIndex) => {
    this.setState({
      activeSlideIndex: newActiveSlideIndex,
    });
  };

  renderSlides = () => {
    const commonStyle = {
      background: "transparent",
      color:"#333",
      border: 'none',
      width: 60,
      height: 60,
      minWidth: 60,
      alignSelf: "center"
    }

    return (
      <Carousel
        activeSlideIndex={this.state.activeSlideIndex}
        onRequestChange={this.setActiveSlideIndex} 
        forwardBtnProps={{
          children: ">",
          style: commonStyle
        }} 
        backwardBtnProps={{
          children: "<",
          style: commonStyle
        }}
        itemsToShow={1}
        itemsToScroll={1} 
        resposiveProps={[{maxWidth: 767, itemsToShow: 2}]} //only show 1 tem when we go to 768px or less
      >
        <div className="inner_slide pirates_slide_0" onClick={(e)=>this.openSlider(0)}></div>
        <div className="inner_slide pirates_slide_1" onClick={(e)=>this.openSlider(1)}></div>
        <div className="inner_slide pirates_slide_2" onClick={(e)=>this.openSlider(2)}></div>
        <div className="inner_slide pirates_slide_3" onClick={(e)=>this.openSlider(3)}></div>
 
      </Carousel>
    )
  }

  openSlider = (index) => {
    let back = "pirates_slide_" + index;
    this.setState({showModal: true, modalBackground: back, activeSlideIndex: index});
  }

  handleClose = () => {
    this.setState({showModal: false});
  }

  changeSlider = (sliderIndex) => {
    let back = "pirates_slide_" + sliderIndex;
    this.setActiveSlideIndex(sliderIndex);
    this.setState({modalBackground: back});
  }

  movePrevious = () => {
    let current = this.state.activeSlideIndex;
    if(current === 0) {
      current = 3;
    } else {
      current -=1;
    }
    this.changeSlider(current);
  }

  moveNext = () => {
    let current = this.state.activeSlideIndex;
    if(current === 3) {
      current = 0;
    } else {
      current +=1;
    }
    this.changeSlider(current);
  }

  render() {
    return  (
      <div className="main main-games-pirates">
      <HeaderPage title="GGDAPP" subtitle="PIRATES 2048"/>  
     
      <Modal show={this.state.showModal} 
      onHide={this.handleClose} 
      centered size="lg" className='game-modal'>
        <Modal.Body>
            <div onClick={this.movePrevious} className='modal-content-previous'>&lt;</div>
            <div onClick={this.handleClose} className={`modal_img_container ${this.state.modalBackground}`}></div>
            <div onClick={this.moveNext} className='modal-content-next'>&gt;</div>
        </Modal.Body>
        {/*<Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>*/}
      </Modal>

      <div className="box box-games">
  
        <div className="games_container">

          <div className="game_info">
              <div className="first_col">
                <div className="pirates_icon"></div>
                <a className="button_dapp" target="_blank" href="https://pirates2048.com/" rel="noreferrer">Open Dapp</a>
                <Link className="button_dapp" to="/stake/polygon/3">Staking pool</Link>
              </div>
              <div className="second_col">  
                <h2>Pirates 2048</h2>
                Pirates 2048 is a browser based, multiplayer, futuristic pirate ship battle game, with elements of action and strategy.
                <p className="p-row">
                  <a target="_blank" href="https://twitter.com/pirates2048" rel="noreferrer"><div className="social_min twitter_min"></div></a>
                  <a target="_blank" href="https://discord.gg/Keuj2uP" rel="noreferrer"><div className="social_min discord_min"></div></a>
                </p>
              </div>
          </div>

          <div className="game_media">
            <div className="slides-container">
            {this.renderSlides()}
            </div>
          </div>

        </div>{/* end games_container*/}


      </div>{/*end box*/}

      <div className="game_video">
            <div className='video_container'>
              <iframe className='video' src="https://www.youtube.com/embed/TZ9dlhsg2qI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>  
      </div>
     
      <FooterPage extraClass="relative_footer"/> 
      
       
      </div>
    );
  }
}

/*
<div className="game_video">
          <div className='video_container'>
          <iframe className='video' src="https://www.youtube.com/embed/TZ9dlhsg2qI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>  
        </div>
*/

export default Pirates;
