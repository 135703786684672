import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class PlayCatalogPage extends React.Component{
  render() {
    //innerBoxPlay
    return  (
      <div className="main playbox-main">
      <HeaderPage title="Play Catalog" subtitle="GAMES AND DAPPS CATALOG"/>  
        <div className="box playbox">
          <Link className="outerBox outerBoxPlay playBox1" to="/pirates">
            <div className="playBox-inner-wrapper">
            <div className="playBox-inner playBox1-inner"></div> 
            <div className="innerBox innerBoxPlay">
              <div className="box-frame-title"><span className="smaller-title">Game</span><br/><div className="wrapped-div">Pirates 2048</div></div>
            </div>
            </div>
          </Link>
        {/*
          <Link className="outerBox outerBoxPlay playBox2" to="/cryptochaser">
          <div className="playBox-inner-wrapper"> 
            <div className="playBox-inner playBox4-inner"></div> 
            <div className="innerBox innerBoxPlay">
              <div className="box-frame-title"><span className="smaller-title">Game</span><br/><div className="wrapped-div">Crypto Chaser</div></div>
            </div>
          </div> 
    </Link>*/}

          <Link className="outerBox outerBoxPlay playBox2" to="/simthunder">
          <div className="playBox-inner-wrapper"> 
            <div className="playBox-inner playBox2-inner"></div> 
            <div className="innerBox innerBoxPlay">
              <div className="box-frame-title"><span className="smaller-title">Marketplace</span><br/><div className="wrapped-div">SimThunder</div></div>
            </div>
          </div> 
          </Link>

          <div className="outerBox outerBoxPlay playBox3">
          <div className="playBox-inner-wrapper"> 
            <div className="playBox-inner playBox3-inner"></div>
            <div className="innerBox innerBoxPlay">
                <div className="box-frame-title">
                  <span className="smaller-title">Game</span><br/><span><div className="wrapped-div">Soccer Manager<div className="disabledLink"> (Coming soon)</div></div></span>
                </div>
            </div>
          </div>
          </div>
        </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default PlayCatalogPage;
